.item {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  @media (min-width: 1400px) {
    height: 100%;
    min-width: 1400px;
    margin: 0 auto;
    padding: 20px 0 0 0;
  }
}

.heading{
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #333333;
}

.main {
  background: rgba(#71BF44, 0.15);
  padding: 20px 40px;
  margin: 30px 0 50px;
  @media (max-width: 767px) {
    padding: 20px;
  }

  :global {
    .requisites-privat {
      padding: 15px 0;

      h3 {
        color: #4F4F4F;
        font-size: 18px;
        line-height: 21px;
      }

      &__card {
        font-size: 20px;
        line-height: 23px;
        color: #4F4F4F;
      }

      p {
        color: #4F4F4F;
        margin-top: 10px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }

    }

    .requisites-description {
      h2 {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        color: #333333;
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        line-height: 21px;
        color: #4F4F4F;
        margin-bottom: 20px;
        @media (max-width: 767px) {
          font-size: 17px;
        }
      }
    }
  }
}