.upload{
  border: 1px dashed #E0E0E0;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
  :global{
    .photo-list{
      display: flex;
      flex-wrap: wrap;
    }
    .upload-button{
      position: relative;
      width: 100%;
      height: 100%;
      cursor: pointer;
      >input[type='file']{
          background: white;
          cursor: inherit;
          display: block;
          filter: alpha(opacity=0);
          font-size: 100px;
          min-height: 100%;
          min-width: 100%;
          opacity: 0;
          outline: none;
          position: absolute;
          right: 0;
          text-align: right;
          top: 0;
        }
    }
  }
}