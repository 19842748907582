.modal{
  font-size: 16px;
  line-height: 21px;
  :global{
    .ant-modal-body{
      padding: 0;
      >div:first-child{
        padding: 20px 20px 0 20px;
      }
      .form{
        &__main-block{
          padding: 10px 20px 20px 20px;
          p{
            margin: 20px 0 0 0;
          }
        }
        &__info-block{
          background: #F2F2F2;
          padding: 20px;
          margin-bottom: 20px;
          h3{
            font-weight: 600;
            color: #333333;
          }
          &_address{
            margin-bottom: 10px;
          }
          &_request{
            color: #828282;
          }
        }
        &__footer-block{
          border-top: 1px solid #E0E0E0;
          padding: 20px;
        }
      }
    }
  }
}