.loading {
    overflow: hidden;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.35);
    height: 100%;
    width: 100%;
}

.loading .anticon svg{
    width: 4rem;
    height: 4rem;
}
