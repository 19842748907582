.list{
  padding: 20px;
  max-width: 1440px;
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
  :global{
    .custom-link{
      cursor: pointer;
      color: #0A57BD;
      font-weight: 700;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
    .custom-btn-edit{
      color: #fff;
      border-color: #6C757D;
      background: #6C757D;
      &:hover{
        color: #6C757D;
        background: #fff;
        border-color: #6C757D;
      }
    }
    .custom-btn-remove{
      color: #DC3545;
      border-color: #DC3545;
      background: #fff;
      margin-left: 20px;
      &:hover{
        color: #fff;
        background: #DC3545;
        border-color: #DC3545;
      }

    }
    .filter{
      padding: 10px 0 20px 0;
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 0;
      }
      &__item{
        background: #fff;
        padding: 5px 2px 5px 5px;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        max-width: 420px;
        @media (max-width: 767px) {
          margin: 0 0 15px 0;
          min-width: 374px;
        }
        @media (max-width: 575px) {
          margin: 0 0 15px 0;
          min-width: 260px;
        }

        &:not(:first-child){
          margin-left: 10px;
          padding: 0;
          background: transparent;
          border: none;
          @media (max-width: 767px) {
            margin-left: 0;
          }
          .ant-calendar-picker-input{
            height: 34px;
            max-width: 260px;
          }
        }
        &_clear{
          text-decoration: underline;
          color: #0A57BD;
          cursor: pointer;
          font-size: 16px;
          line-height: 23px;
          margin-left: 10px;
          @media (max-width: 767px) {
            margin-left: 0;
          }
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    .filter-admin{
      position: relative;
      @media (max-width: 767px) {
        display: flex;
        align-items: flex-start;
        >*{
          margin: 0 0 15px 0;
        }
      }
      @media (min-width: 767px) {
        >div:last-child{
          position: absolute;
          right: 0;
          top: 11px;
        }
      }
      .ant-btn-primary {
        background-color: #219653;
        border-color: #219653;
        &:hover, &:focus {
          background-color: #27AE60;
          border-color: #27AE60;
        }
      }

    }
    .request{
      margin-bottom: 20px;
    }
    .gift-list-row{
      margin: 0 -15px;
      align-items: stretch;
    }
  }
  @media (min-width: 1440px) {
    min-width: 1440px;
    margin: 0 auto;
  }
}