.scroll{
  max-height: calc(100vh - 128px);
  width: calc(100% + 48px);
  overflow-y: auto;
  margin: -24px;
  padding: 15px 24px;
  font-size: 16px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #ebeff2;
  }
  &::-webkit-scrollbar-thumb {
    background: #84939a;
  }
  @media (max-width: 769px) {
    max-height: 100%;
  }
  :global{
    .confirm__request{
      border-top: 1px solid #E0E0E0;
      border-bottom: 1px solid #E0E0E0;
      padding: 15px 0;
      margin: 15px 0;
      p {
        line-height: 19px;
      }
      &_switch{
        display: flex;
        align-items: center;
        line-height: 19px;
        h4{
          color: #0A5FD1;
          font-weight: 700;
          margin-right: 20px;
          font-size: 16px;
        }
        h5{
          color: #333333;
          margin-right: 20px;
          font-size: 16px;
        }
      }
    }
    .ant-form-item-children{
      .ant-select{
        min-width: 100%;
      }
    }
    .сonfirm__terms_conditions{
      margin: 15px;
      font-size: 16px;
    }
    .not-approved-badge{
      position: absolute;
      top: 15px;
      right: 15px;
      display: inline-block;
      background: #DC3545;
      color: #fff;
      border-radius: 4px;
      padding: 0 5px;
    }
    .ant-input{
      font-size: 16px;
    }
  }
}

.amount{
  padding: 15px 20px;
  background: #F2F2F2;
  font-size: 16px;
  line-height: 23px;
  color: #4F4F4F;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-top: -20px;
  margin-bottom: 15px;
  :global{
    span{
      color: #DC3545;
    }
    .desc{
      display: block;
      font-size: 14px;
      padding-top: 10px;
    }
  }
}

.footer{
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #E0E0E0;
  background: #fff;
  padding: 20px;
  @media (max-width: 769px) {
    position: relative;
    padding: 20px 0;
  }
}