.item{
  border: 2px solid #ECEFF1;
  border-radius: 4px;
  position: relative;
  margin: 5px;
  padding: 0;
  height: 145px;
  width: 145px;
  align-items: flex-start;
  :global{
    .file-name-tooltip {
      background: #F7F9F9;
      color: #353A45;
      display: block;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      line-height: 1.3;
      position: absolute;
      padding: 2px 5px;
      margin-right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      visibility: visible;
      cursor: pointer;
    }
    .file-name {
      width: 100%;
      color: #2F333E;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-size {
      display: block;
      color: #84939A;
    }
  }
}

.grid{
  background: #fff;
  color: #84939A;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 300;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  position: relative;
  height: calc(145px - 41px);
  min-width: 141px;
  overflow: hidden;
  :global{
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      color: #84939a;
      cursor: pointer;
      background: #EBEFF2;
      width: 20px;
      height: 20px;
      border: 0;
      line-height: 0;
      padding: 0 2px;
      margin: 0;
      border-bottom-left-radius: 3px;
      &:hover{
        color: #039be5;
      }
      &:focus{
        outline: none;
      }
      .svg-inline--fa{
        height: 17px;
      }
      .fa {
        font-size: 14px;
      }
    }

    img {
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
      width: 141px;
      height: 104px;
      object-fit: cover;
    }
  }
}